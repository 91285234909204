import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthResponse } from '../models/Auth';
import { UsersResponse, addUserResponse } from '../models/users';
import { SettingsResponse } from '../models/settings';
@Injectable({
  providedIn: 'root'
})
export class ApisService {
  BaseUrl ='http://api.masaraty.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  public login(body):  Observable<AuthResponse>{
    // this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    // .set("Authorization","Bearer " + this.token);
    return this.http.post<AuthResponse>(this.BaseUrl+ 'admin/admin_general/login' ,body,{headers});
  }


  public getUsers():  Observable<UsersResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<UsersResponse>(this.BaseUrl+ 'admin/admin_auth/all_users' ,{headers});
  }

  public addUser(body):  Observable<addUserResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<addUserResponse>(this.BaseUrl+ 'admin/admin_auth/add_user' ,body,{headers});
  }


  public editUser(body):  Observable<addUserResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<addUserResponse>(this.BaseUrl+ 'admin/admin_auth/edit_user' ,body,{headers});
  }

  public deleteUser(id : number):  Observable<addUserResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<addUserResponse>(this.BaseUrl+ 'admin/admin_auth/delete_user/'+ id ,null,{headers});
  }

  public sendNotification(body):  Observable<addUserResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<addUserResponse>(this.BaseUrl+ 'admin/admin_auth/multi_notification' ,body,{headers});
  }


  public getSettings():  Observable<SettingsResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<SettingsResponse>(this.BaseUrl+ 'admin/admin_auth/get-settings' ,{headers});
  }

  
  public sendSettings(body : FormData):  Observable<SettingsResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<SettingsResponse>(this.BaseUrl+ 'admin/admin_auth/change-settings' ,body,{headers});
  }

}
